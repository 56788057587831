import React, { useState } from 'react';
import { MenuItem } from './MenuType';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface MenuProps {
    menuItem: MenuItem[];
    activeItem: string | null;
    isOpen: boolean;
    onClickItem : (title : any) => void;
}
const MenuComponent : React.FC<MenuProps> = ({menuItem, activeItem, isOpen, onClickItem}) => {
    const [submenuState, setSubmenuState] = useState<{ [key: string]: boolean }>({});

    // Toggle submenu visibility
    const handleSubmenuToggle = (title: string) => {
        setSubmenuState((prevState) => ({...prevState, [title]: !prevState[title]}));
    };
    return(
        <List>
            {menuItem.map((item, index) => (
            <div key={index}>
                <ListItem onClick={() => {
                    handleSubmenuToggle(item.title);
                    onClickItem(item.title);
                    }}
                    sx={{
                        color:activeItem === item.title ?  "rgb(59 99 82)" : 'lightgray',
                        '&:hover':{
                            color:'rgb(84, 138, 115)',
                            cursor:'pointer',
                        }
                    }}>
                <ListItemIcon>{item.icon}</ListItemIcon> 
                <ListItemText primary={item.title} primaryTypographyProps={{sx:{fontWeight:"bold", color:'grey', minWidth:'100px'}}} />
                    {item.submenu && (<span>{submenuState[item.title] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>)}
                </ListItem>
                {item.submenu && (
                <Collapse in={submenuState[item.title]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {item.submenu.map((subItem, subIndex) => (
                        <ListItem onClick={(e) => onClickItem(subItem.title)} 
                        sx={{
                            color:activeItem === item.title ?  "rgb(59 99 82)" : 'lightgray',
                            '&:hover':{
                                color:'rgb(84, 138, 115)',
                                cursor:'pointer',
                            }}} key={subIndex} >
                        <ListItemIcon></ListItemIcon>
                        {isOpen && <ListItemText primaryTypographyProps={{sx:{fontSize:'14px', color:'grey'}}} primary={subItem.title} />}
                        </ListItem>
                    ))}
                    </List>
                </Collapse>
                )}
            </div>
            ))}
        </List>
    );
}

export default MenuComponent;