import React from 'react';
import './App.css';
import {createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import Dashboard from './pages/dashboard/Dashboard';

const theme = createTheme({
  palette:{
    background:{
      default:'#f4f4f4',
      paper:'#ffffff'
    }
  }
});

const App : React.FC = () => {
  
  return (
    <ThemeProvider theme={theme}>
      {/* Resets default styles*/}
      <CssBaseline />  
      {/* Dashboard */}
      <Dashboard />
    </ThemeProvider>
  );
}

export default App;
