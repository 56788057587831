import {Apps, BusinessCenter, CalendarMonth, Category, Checklist, ControlCamera, Diversity3, ListAlt, Logout, PendingActions, Settings, Speed, Support, SupportAgent, TextSnippet, Wallet } from '@mui/icons-material';
import { MenuItem } from './MenuType';
 // Menu structure with submenus and icons
 const functionalMenu: MenuItem[] = [
    { title: "Dashboard", to:'/dashboard/overview', icon: <Speed />,
        submenu: [
            { title: "Översikt", to:'/dashboard/overview' },
            { title: "Insikter",to:'/dashboard/insights' },
        ]},
    { title: "Offert", to:'/offert',icon: <Wallet /> },
    { title: "Order", to:'/order', icon: <ListAlt /> },
    { title: "Uppdrag", to:'/', icon: <CalendarMonth /> },
    { title: "Resursplanering", to:'/', icon: <Apps /> },
    { title: "Tidrapportering", to:'/', icon: <PendingActions />},
    { title: "Export", to:'/', icon: <Checklist />,
        submenu: [
            { title: "Fakturaunderlag", to:'/overview' },
            { title: "Löneunderlag",to:'/insights' },
        ]},
    { title: "Ärendehantering", to:'/', icon: <TextSnippet />},
];

// Resource Menu
const resourceMenu : MenuItem[] = [
    { title: "Artiklar", to:'/', icon: <Category />,
        submenu: [
            { title: "Produkter", to:'/overview' },
            { title: "Tjänster",to:'/insights' },
        ]},
    { title: "Kunder",  to:'/', icon: <BusinessCenter />,
        submenu: [
            { title: "Företag", to:'/overview' },
            { title: "Privat",to:'/insights' },
        ]},
    { title: "Resurser", to:'/', icon: <Diversity3 />,
        submenu: [
            { title: "Medarbetare", to:'/overview' },
            { title: "Fordon",to:'/insights' },
        ]},
];

// Util menu
const utilMenu : MenuItem[] = [
    { title:"Mallar", to:'/', icon:<ControlCamera/>,
        submenu:[
            { title:'Kund',to:'/' },
            { title:'Medarbetare', to:'/'},
            { title:'Kalendar', to:'/'},
            { title:'Kunskap', to:'/'},
            { title:'Egenskaper', to:'/'},
            { title:'Mics', to:'/'},
        ]
    }];

// Setting Menu
const settingsMenu : MenuItem[] = [
    { title:'Support', to:'', icon:<SupportAgent/>},
    { title:'Instäningar', to:'', icon:<Settings/>},
    { title:'Logga ut', to:'', icon:<Logout/>},
];

export {functionalMenu, utilMenu, settingsMenu, resourceMenu}