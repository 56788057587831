import React, { useState } from 'react';
import { Drawer,Button,Divider, Box, Typography, IconButton, Menu } from '@mui/material';
import MenuComponent from '../../components/navigation/MenuComponent';
import { functionalMenu, resourceMenu, settingsMenu, utilMenu } from '../../components/navigation/Menu';
import { Close, DragHandle } from '@mui/icons-material';
import Header from '../../components/navigation/Header';
import { themeProps } from '../../configs/defaultTheme';

const drawerCssProps = {
    openWidth: 240,
    closedWidth: 60,
};

const Dashboard : React.FC = () => {
    const [activeItem, setActiveItem] = useState<string>("");
    const [isOpen, setIsOpen] = useState<boolean>(true);     
    // Handle toggle drawer
    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };
    // Handle itemClick
    const handleItemClick = (title: string) => {
        setActiveItem(title);
        setIsOpen(true); // automatically expand drawe
    };

    return (
        <>
        <Drawer 
            anchor="left"
            open={true}
            variant="persistent"
            sx={{
                width: isOpen ? drawerCssProps.openWidth : drawerCssProps.closedWidth,
                transition: '0.3s',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    border:'none',
                    width: isOpen ? drawerCssProps.openWidth : drawerCssProps.closedWidth,
                    overflowX:'hidden',
                    transition: '0.3s', 
                    '&::-webkit-scrollbar': {
                        display: 'none', // For Chrome, Safari, and Edge to hide the scrollbar
                    },
                },
            }}
            ModalProps={{
                keepMounted:true,
                hideBackdrop:true,
            }}>
            {/* Profile Section */}
            <Box sx={{display:'flex', flexFlow:'row', width:'100%', minWidth:drawerCssProps.openWidth, margin:'10px 0px 10px 0px'}}>
                {/* Profile Picture */}
                <Box component="img" onClick={toggleDrawer} sx={{ height: 40, width: 40, borderWidth:"2px", border:'solid', padding:"1px", borderRadius:50, borderColor:themeProps.color, margin:"10px", '&:hover':{cursor:'pointer'}}} alt="The house from the offer." src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"/>
                {/* Profile Data */}
                <Box sx={{display:'flex', flexFlow:'column', marginTop:'10px'}}>
                    <Typography sx={{fontWeight:'bold'}}>Alex</Typography>
                    <Typography sx={{fontSize:'12px'}}>Dygnet Runt</Typography>
                </Box>
                {/* Toggle Button */}
                <IconButton onClick={toggleDrawer} sx={{height:50, width:50, margin:'10px 0px 0px 50px'}}>
                    <Close/>
                </IconButton>
            </Box>
            <MenuComponent menuItem={functionalMenu} activeItem={activeItem} isOpen={isOpen} onClickItem={handleItemClick}/>
            <Divider sx={{ width: '90%', margin: '0 5%' }} />
            <MenuComponent menuItem={resourceMenu} activeItem={activeItem} isOpen={isOpen} onClickItem={handleItemClick}/>
            <Divider sx={{ width: '90%', margin: '0 5%' }} />
            <MenuComponent menuItem={utilMenu} activeItem={activeItem} isOpen={isOpen} onClickItem={handleItemClick}/>
            <Divider sx={{ width: '90%', margin: '0 5%' }} />
            <MenuComponent menuItem={settingsMenu} activeItem={activeItem} isOpen={isOpen} onClickItem={handleItemClick}/>
        </Drawer>
        {/* Page View */}
        <Box sx={{display:'flex', width: isOpen ? `calc(100% - ${drawerCssProps.openWidth}px)`: `calc(100% - ${drawerCssProps.closedWidth}px)`, flexFlow:'row', padding:"20px 50px 20px 50px", transition:'margin 0.3s', marginLeft: isOpen ? `${drawerCssProps.openWidth}px` : `${drawerCssProps.closedWidth}px`,}}>
            <Header path={`Startsida / ${activeItem}`} title={activeItem}/>
        </Box>
        </>
    );
};

export default Dashboard;