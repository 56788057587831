import { Search } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { themeProps } from "../../configs/defaultTheme";
import micronIq from "../../assets/images/micronIq_icon.png";

interface HeaderProps {
    components?: React.FC[];
    path: string;
    title: string;
};

const Header : React.FC<HeaderProps> = ({components, path, title}) => {
    const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
    const handleOnClickSearch = () => {
        setIsSearchActive(!isSearchActive);
    }
    return (
        <Box sx={{display:'flex', flexFlow:'column', width:'100%'}}>
            {/* Search Bar section */}
            <Box sx={{display:'flex', flexFlow:'row', width:'100%'}}>
                <Box component="img"  sx={{ height: 40, width: 40, borderRadius:50}} src={micronIq}/>
                <TextField
                    id="input-with-icon-textfield"
                    label=""
                    sx={{
                        marginLeft:'20px',
                        bgcolor:'white',
                        borderRadius:50,
                        transition:'width 0.3s',
                        width: isSearchActive ? "100%" : "40px",
                    }}
                    slotProps={{
                    input: {
                        disableUnderline:true,
                        startAdornment: (
                            <IconButton onClick={handleOnClickSearch} sx={{height:40, width:40, bgcolor:'white'}}>
                                <Search/>
                            </IconButton>
                        ),
                    },
                    }}
                    variant="standard"
                />
            </Box>
            {/* Path section */}
            <Typography color='grey' fontSize='14px' padding="20px 0px 20px 0px">{path}</Typography>
            {/* Title and components section */}
            <Box>
                {/* Title */}
                <Typography fontSize={50} fontWeight={'bold'} fontStyle={'italic'} color={themeProps.color}>{title}</Typography>
                {/* Components */}
                <Box> 

                </Box>
            </Box>
        </Box>
    );
}

export default Header